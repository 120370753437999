/* +++++++++++++++++++
+++ LAYOUT: FOOTER +++
+++++++++++++++++++ */

// Layout of the site footer

.footer {
    margin-top: 0;
    padding-bottom: 0;
    padding-top: $gap * 3;
    z-index: map-get($zindex, footer);

    @include break(medium) {
	    padding-top: $gap * 5;
    }

    &-header {
	    @extend %flex;
	    align-items: center;
	    border-bottom: solid 2px $white;
	    justify-content: space-between;
	    margin-bottom: $gap * 2;
	    padding-bottom: $gap * 2;

		* {
			margin-top: 0;
		}

		.footer-slogan {
			@extend %h6;
			margin: $gap * 1.5 auto 0 0;
			width: 100%;

			@include break(medium) {
				margin: 0 0 0 auto;
				width: auto;
			}
		}

		.search {
			margin: 0;
			width: 100%;

			@include break(small) {
				width: auto;
			}

		    &-input {
		        background: rgba($white, 0.1);
		        color: $white;
		    }
		    &-button {
			    top: 0;
		    }
		}
    }

    &-logo {
        display: block;
        margin: $gap 0;
	    max-width: 350px;
        width: 100%;

        @include break(small) {
	        display: inline-block;
	        margin: 0;
	        width: auto;
        }

        img {
        	width: auto;
        }

    }

    &-nav {
	    &:first-child {
		    border-top: 0;
		    margin-left: 0;
		    padding-top: 0;
	    }
	    &:nth-child(3) {
		    @include break(small) {
		    	margin-left: 0;
		    }
		    @include break(medium) {
		    	margin-left: 2%;
		    }
	    }

	    &-outer {
			@extend %flex;
			* {
				margin-top: 0;
			}
	    }

	    &.social {
		    @include flexbox(100%);
            // margin: 0;
			// text-align: center;

            @include break(small) {
	            @include flexbox(auto);
	            // text-align: left;
            }
	    }

        &-item {
            margin-top: 0;

            .footer-nav-item-title {
	            font-size: 1rem;
	            padding-bottom: $gap;
            }

            .nav-item-link {
	            font-size: 1.05rem;
	            font-weight: $bold;
	            margin: 0;
	            padding: 0 0 $gap / 2 0;

	            &:hover {
		            color: $secondary;
		            // text-decoration: underline;
	            }
            }

            &.social {
	            // display: inline-block;
	            // margin-right: $gap / 1.5;
	            // text-align: center;

	            &:not(:last-child) {
	            	margin-bottom: $gap;

	            	@include break(medium) {
		            	margin-bottom: $gap * 1;

	            	}
	            }

	            &:last-child {
		            // margin-right: 0;
	            }

	            .nav-item-link {
					@extend %transition;
					background-color: $white;
					border-radius: 100%;
					height: 2.5rem;
					line-height: 1;
		            padding: 0;
					position: relative;
					width: 2.5rem;

					@include break(medium) {
					display: inline-block;

					}

					&:hover {
						background-color: $secondary;
						.icon {
							color: $white;
						}
					}
					
					&.Twitter {
						&:after {
							@include centerer(true, true);
							content: '𝕏';
							color: $black;
							font-size: 1.5rem;
						}
						.icon {
							color: transparent;
						}
						&:hover {
							&:after {
								content: '𝕏';
								color: $white;	
							}
						}
					}

					&.Instagram, &.YouTube {
						.icon {
							padding: 2px;
						}
					}

					.icon {
						@include centerer(true, true);
						color: $primary;
						height: 2rem;
						width: 2rem;
						margin: 0;
					}

					&.Linkedin {
						.icon {
							padding: 3px;
						}
					}

					&.Bluesky {
						.icon {
							height: 1.5rem !important;
    						width: 1.5rem !important;
						}
					}

					span {
						position: absolute;
						margin: $gap / 1.75 0 0 $gap * 3.25;
						vertical-align: middle;
					}
	            }
            }
        }
    }

    p {
		// font-size: 0.9rem;
    }

    &-details, &-nav {
	    @include flexbox(100%);
	    border-top: solid 1px $white;
	    padding-top: $gap * 1.5;
	    margin-bottom: $gap;

	    @include break(small) {
		    @include flexbox(48%);
		    border-top: 0;
    	    margin-left: 4%;
    	    padding-top: 0;
	    }

	    @include break(medium) {
			// @include flexbox(16.22%);
			@include flexbox(22%);
			margin-left: 2%;
	    }
    }

	&-details {
		margin-right: 0;

        .footer-nav-item-title {
            font-size: 1rem;
            padding-bottom: $gap;
        }
        p {
	        padding-bottom: $gap;

	        a {
		        &:hover {
			        // color: $primary;
		        }
	        }
        }
	}

	&-credits {
		// background-color: $primary;
		color: $white;
		padding: $gap * 1.5 0;

		@include break(medium) {
			margin: $gap * 1.5 0 $gap * 5;
		}

		* {
			margin-top: 0;
		}
		.container {
			@extend %flex;
		}
		p {
			@include flexbox(100%);
			font-size: 1rem;
			// font-weight: $bold;

			@include break(medium) {
				@include flexbox(50%);
			}

			a {
				@extend %transition;
				text-decoration: underline;

				&:hover {
					color: $secondary;
				}
			}

			&:nth-child(1) {
				border-bottom: 0.5px solid $white;
				margin-bottom: $gap * 1.75;
				padding-bottom: $gap * 1.75;

				@include break(medium) {
					@include flexbox(100%);
				}
			}


			&:nth-child(2) {
				margin-left: 0;

				@include break(medium) {
					@include flexbox(65%);
				}
			}

			&:nth-child(3) {
				@include break(medium) {
					@include flexbox(35%);
					text-align: right;
				}
			}
		}
	}

}
