	/* ++++++++++++++++++
+++ LAYOUT: CARDS +++
++++++++++++++++++ */

// Cards are more visually interesting and content-light than a standard listing page.

.cards {
    @extend %flex;
    position: relative;
    width: 100%;

    > * {
        @extend %flex;
        @include flexbox(100%);
        margin: 0 0 $gap * 1.5;
    }

    @include break(tablet) {
        > * {
	        @include flexbox(50%);
        }
    }

    @include break(medium) {
        > * {
	        @include flexbox(33.33%);
        }
    }
    &-outer {
		position: relative;
	    .section-header {
		    &.container {
			    @include break(medium) {
			    	padding: 0 $gap * 2;
			    }
		    }
	    }
	    .container {
		    @include break(large) {
		    	padding: 0;
		    }
	    }
    }
}

.card {
    @extend %card;
    @extend %flex;
    align-content: flex-start;
    margin: 0;
    overflow: hidden;
    width: 100%;

    @include break(tablet) {
	    // margin: 0 $gap * 2;
	    margin: 0 $gap;
	    padding: $gap;

	    .featured-cards &,
	    .bg-image & {
	    	margin: 0 $gap 0 0;
	    }
    }
	&-summary {
		line-height: 1.6rem;
	}
	
	&-bg-image {
		background-repeat: no-repeat;
		background-size: cover;
		height: 80%;
		display: none;
		left: 0;
		position: absolute;
		top: 0;
		width: 50%;
		
		@include break(medium) {
			display: block;	
		}
		@include break(large) {
			width: 40%;	
		}
	}

	&-symbol {
		@extend %transition;
		background-color: $white;
		border-radius: 100%;
		border: 2.5px solid $purple;
		box-shadow: $shadow;
		color: $purple;
		margin-right: $gap * 2;
		margin-bottom: -$gap * 2.5;
		z-index: 1;
		padding: $gap ;

		@include break(medium) {
		border: 3.5px solid $purple;
		padding: $gap * 2;
			margin-right: auto;
			margin-bottom: -$gap;
			margin-left: $gap * 2;
		}

		.icon {
			height: 30px;
			width: 30px;

			@include break(medium) {
				height: 40px;
				width: 40px;
			}
		}
	}

	&:hover,
	.hovered {
		.card-symbol {
			background-color: $purple;
			color: $white;
		}

		.card-wrap {
			box-shadow: $shadow-strong;
		}

		.card-image {
			img {
				transform: scale(1.05);
			}
		}

		.card-header-title {
			color: $secondary;

    		.featured-cards & {
    			color: inherit;
    		}
		}
	}

    &-image {
        @extend %image-hover;
        // border-radius: $corner $corner 0 0;
        display: block;
        margin: 0;
        position: relative;
        width: 100%;
        height: 250px;

	    img {
			@extend %object-fit;
	    }

	    .img-bleed & {
        	margin-bottom: - $gap * 2;
	    }
    }

    &-wrap {
    	@extend %transition;
	    align-self: flex-start;
	    background-color: $white;
	    box-shadow: $shadow;
	    margin: $gap;
		width: 100%;

        @include break(medium) {
        	margin: 0;
        }

        .img-bleed & {
    		margin: auto auto $gap $gap;
        	width: 90%;
        	z-index: 1;

        	@include break(tablet) {
        		margin: auto auto 0 0;
        	}
        }

        &-inner {
	        padding: $gap * 2;

	        .featured-cards & {
	        	padding: 0;
	        }

	        .card-details {
	        	border-bottom: 1px solid $grey70;
	        	font-size: 1rem;
	        	margin: $gap / 2 0;
	        	padding-bottom: $gap * 1.25;
	        	// font-weight: $bold;

	        	.card-detail {
				    @extend %font-label;

	        		&:not(:last-of-type) {
		        		margin-bottom: $gap * 0.75;
	        		}
		        	// list-style-type: disc;
	        		// margin-left: $gap * 1.25;

	        		span {
	        			@extend %font-body;
	        			display: block;
	        			font-size: 1.1rem;
	        			letter-spacing: 0;
	        			text-transform: none;
	        		}
	        	}
	        }
        }
    }

    &-header {
		@extend %transition;
        margin-top: 0;

        .featured-cards & {
        	background-color: $grey;
        	color: $white;
	        padding: $gap * 2;
	        position: relative;

	        .card-header-date {
	        	// margin-left: $gap * 2.5;
	        }

        	&:before {
        		content: url(../svg/ribbon.svg);
        		position: absolute;
        		top: 0;
        		right: $gap * 2;
        	}
        }

        + .card-summary {
	        margin-top: $gap / 2;
			line-height: 1.6rem;

	        .featured-cards & {
		        padding: $gap * 1.5 $gap * 2 $gap * 2;
	        }

	        p {
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
	        }
        }

        &-title {
	        // font-size: 1.5rem;
    		font-size: 1.2rem;
	       
	        // padding-right: $gap * 2;


	        	// padding-left: -($gap * 2.5);
	        	// text-indent: $gap * 2.5;

	        .img-bleed &,
        	.bg-image & {
        		// font-size: 1.2rem;
        	}

            a {
            	@extend %transition;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 6;
				-webkit-box-orient: vertical;

				&:hover {
					text-decoration: underline;
				}
            }

        }

        &-date {
            @extend %font-meta;
        	color: $grey30;
            display: block;
            margin-bottom: $gap / 2;

            .featured-cards & {
            	color: $white;
            }
        }
    }

	.img-bleed & {
		&:hover,
		.hovered {
			.card-header-title {
				color: $green;
			}
		}
	}

	.bg-image & {
		&:hover,
		.hovered {
			.card-header-title {
				color: purple;
			}
		}

		.card-header-title {
			a {
				&:hover {
					// text-decoration: none;
				}
			}
		}
	}

    &-button {
	    &-outer {
		    margin-top: $gap;

	        .card-text-link {
		    	@extend %font-label;
	        	// text-decoration: underline;

			    &:after {
			        transition: margin 0.2s ease;
			        content: '→';
			        line-height: 1;
			        margin-left: 0.35em;
			    }

			    &:hover {
			    	color: $secondary;

			        &:after {
			            margin-left: 0.65rem;
			        }
			    }
	        }
	    }
    }
}

// Report layout

.reports {
	.card {
		&-image {
			background-color: $grey90;
			overflow: hidden;

			img {
				@extend %transition;
				bottom: -1rem;
				// border-radius: $corner $corner 0 0;
				box-shadow: $shadow;
				margin: $gap * 3 auto 0;
				position: relative;
				width: 60%;

				&:hover {
					bottom: -0.5rem;
				}
			}
		}
		&-wrap {
			box-shadow: 0px -8px 22px 0px rgba($black, 0.3);
			z-index: 2;
		}
	}
}

.bg-image {
	.cards {
		justify-content: center;
		@include break(large) {
			@include flexbox(75%);
			margin-right: 0;
		}
	}
}

// Listing layout

.listing {
	.card {
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;

		&-wrap {
			border-top: solid 5px $primary;

			&-inner {
				padding: $gap * 1.75 $gap * 2.25;
			}
		}

		&:hover,
		.hovered {
			.card-wrap {
				border-color: $secondary;
			}
		}
	}
}


// Two column layout
.two-col {
	.cards {
		> * {
	        @include break(tablet) {
		        @include flexbox(50%);
	        }

	        .card {
	        	margin: 0;

	            @include break(tablet) {
		            // margin: 0 $gap * 1.5;
		            margin: 0 $gap;
	            }

	            &-image {
		            height: 35vh;
	            }
	        }
		}
	}
}

// Three column layout
.three-col {
	.cards {
		> * {
			@include break(medium) {
				@include flexbox(33.33%);
			}
	        .card {
	            margin: 0 $gap * 1.5;

	            &-image {
		            height: 25vh;
	            }
	        }

		    &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ article {
	            @include flexbox(50%);

	            .card {

		            &-image {
			            height: 35vh;
		            }
	            }
		    }
		}
	}
}

// Four column layout
.four-col {
	.cards {
		> * {
		    @include break(large) {
			    @include flexbox(25%);
		    }
		    .card {
		        // margin: 0 $gap * 1.5;
	            margin: 0 $gap * 0.5;
		    }

		    &:first-child:nth-last-child(3), &:first-child:nth-last-child(3) ~ article {
			    @include flexbox(33.33%);
		    }

		    &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ article {
	            @include flexbox(50%);

	            .card {
		            margin: 0 $gap * 1.5;

		            &-image {
			            @include break(large) {
				            height: 35vh;
			            }
		            }
	            }
		    }
		}
	}
}

.general-listing {
	.cards {
		&>* {
			@include flexbox(100%);
			margin: 0 0 0.5rem;
	
			@include break(tablet) {
				@include flexbox(80%);
			}
		}

		.card {
			

			@include break(tablet) {
				margin: 0 $gap;
				padding: 0.5rem $gap;
			}


			&:hover {
				.card-header-title {
					color: $black;
				}
			}

			&-wrap {
				box-shadow: none;

				&:hover {
					box-shadow: none;
				}

				&-inner {
					padding: 0;

					.metalabel {
						a {
							background: #009CDE;
							color: $white;
							padding: 0.5rem 1rem;
							margin: 1.5rem 0rem 0.5rem;
							display: block;
							width: fit-content;
							text-transform: capitalize;
							
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}

				
			}

			&-header {
				&-title {
					font-size: 2rem;

					&:hover a {
						color : $green;
					}

					& .link::after {
						background-image: url('/assets/svg/open-in-new.svg');
						content: '';
						display: inline-block;
						width: 1.4rem; 
						height: 1.4rem;
						background-size: contain;
						background-repeat: no-repeat;
						margin-left: 1rem;
					}
				}

				&-date {
					display: block;
					font-weight: bold;

					@include break(tablet) {
						display: inline-flex;
						margin-bottom: .5rem;
						gap: 2rem;
					}

					span {
						display: block;
					}
					
				}
			}
		}
	}
	
}