/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

.media-block {
	@extend %flex;
	background-color: $primary;
	border-radius: $corner;
	box-shadow: 0px 0px 30px rgba($black, 0.2);
	min-height: 420px;
	
	&-outer {
		&.right {
			.media-block {
				@include break(medium) {
					flex-flow: row-reverse nowrap;
				}
				&-element {
					@include break(medium) {
						border-radius: 0 $corner $corner 0;
					}
				}
			}
		}
	    &.secondary {
		    .media-block {
				background: $white;
				
				&-info {
					color: $black;
				}
		    }
	    }
	    &.video {
		    .media-block {
			    min-height: auto;   
			    
			    &-element {
				    @include flexbox(100%);
					overflow: hidden;
					
					@include break(medium) {
					    @include flexbox(50%);
					}
					
				    .video-outer {
						display: block;
						padding-bottom: 56.25%; /* 16:9 */
						height: 0;
						position: relative;
			
						iframe {
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
						}   
				    }
			    }
			    &-info {
					@include break(medium) {
					    @include flexbox(50%);
					}
			    }
		    }
	    }
	}
	
    &-element, &-info {
	    margin: 0;
    }
    &-info {
	    @extend %flex;
		align-items: center;
	    color: $white;
	    padding: $gap * 1.5;
	    
	    @include break(small) {
		    padding: $gap * 3;    
	    }
	    
	    @include break(medium) {
		    @include flexbox(50%);
		    padding: $gap * 3;
	    }
	    @include break(large) {
		    padding: $gap * 4 $gap * 3;
	    }
	    
	    .button {
		    font-size: 1.2rem;
		    margin-top: 2rem; 
	    }
    }
    &-content {
	    p {
		    padding-bottom: $gap;
		    
		    a {
			    @extend %text-hover;
			    text-decoration: underline;
		    }
	    }
    }
    &-element {
	    border-radius: $corner 0 0 $corner;
	    overflow: hidden;
	    position: relative;
	    
	    @include break(medium) {
		    @include flexbox(50%);
	    }
	    
	    &-play {
		    @extend .button;
		    @include centerer(true, true);
		    background-color: $red;
		    display: inline-block;
		    width: auto;
		    z-index: 2;
	    }
	    
	    img {
            height: 100%;
            object-fit: cover;
            width: 100%;
            
            @include break(medium) {
	            border-radius: 0;
	            left: 0;
	            position: absolute;
	            top: 0;
            }

            .no-objectfit & {
                width: auto;
            }
	    }
    }
    &-title {
	    font-size: 1.4rem;
	    padding-bottom: $gap / 1.5;
	    
	    @include break(tablet) {
		    font-size: 2rem;	    
	    }
	    
		a {
			@extend %transition;
			
			&:hover {
				color: $primary;
			}
		}
    }
}
