
.two-col.featured-cards {
	.grid {
		&-item {
			@include break(tablet) {
				width: 46%;
			}
		}
	}
}

.three-col.featured-cards {
	.grid {
		&-item {
			@include break(tablet) {
				width: 46%;
			}

			@include break(large) {
				width: 29%;
			}
		}
	}
}

.four-col.featured-cards {
	.grid {
		&-item {
			@include break(tablet) {
				width: 46%;
			}

			@include break(large) {
				width: 21%;
			}
		}
	}
}

.featured-cards {
	.grid {
		/* clear fix */
		&:after {
			content: '';
			display: block;
			clear: both;
		}

		.card-wrap {
			margin: 0;
		}

		article {
			&:nth-of-type(2) {
				.grid-item {
					@include break(tablet) {
						margin-top: $gap * 2;
					}

					@include break(medium) {
						margin-top: $gap * 3;
					}

					@include break(large) {
						margin-top: $gap * 4;
					}
				}
			}
		}

		&-item {
			@extend %transition;
			float: left;
			margin: 0 0 $gap * 2 0;
			max-width: 100%;

			@include break(tablet) {
				margin: 0 2% $gap * 3 2%;
			}

			@include break(medium) {
				margin: 0 2% $gap * 4 2%;
			}

			img {
				display: block;
				height: 250px;
				max-width: 100%;
				object-fit: cover;
				width: 100%;
			}

			&:hover,
			.hovered {
				box-shadow: $shadow;

				.card-header {
					background-color: $blue;

					&:before {
						content: url(../svg/ribbon-active.svg);
						top: -$gap * 0.925;
						// right: $gap;
					}
				}
			}
		}
	}
}