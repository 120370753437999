//++++++++++++++++++++++++++
//+++ HELPERS: ANIMATION +++
//++++++++++++++++++++++++++

//=======================================
// Movement
//=======================================

// fancy transitions
$move-whip: cubic-bezier(0.8, 0, 0.3, 1);
$move-snap: cubic-bezier(0.7, -0.5, 0.3, 1.5);
$move-snap-in: cubic-bezier(0.6, -0.3, 0.7, 0);
$move-snap-out: cubic-bezier(0.2, 0.9, 0.3, 1.3);


%transition {
    @include transition-ease(background, border, box-shadow, color, opacity, transform, background, height, padding, top, right, bottom, left, width);
}
