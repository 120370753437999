/* +++++++++++++++++++++++
+++ LAYOUT: PAGINATION +++
+++++++++++++++++++++++ */

// Pagination links for the listing and features pages

%pagination {
    @extend %flex;
    @extend %font-meta;
    text-align: center;
    justify-content: space-between;

    a {
        @extend %transition;

        &:hover {
            background: $secondary;
            color: $white;
        }

        span {
            display: none;

            @include break(small) {
                display: inline-block;
            }
        }
    }

    &-block {
        margin: 0;
    }

    &-number {
      display: block;
      font-family: $font-header;
    }

    &-element {
        border-radius: $corner;
        display: block;
        padding: $gap / 4 $gap / 2;
        overflow: hidden;
    }

    &-arrow {
        @extend .button;
        @extend .button-small;
        display: block;
    }

    &-current {
        border: 2px solid $secondary;
        color: $secondary;
    }

    @include break(small) {
        justify-content: center;

        &-block {
            margin: 0 $gap / 4;
        }

        &-element {
            display: block;
            padding: $gap / 2 $gap;
        }
    }
}

@if $wordpress {
    .pagination {
        @extend %pagination;
        align-items: center;

        &_block {
            @extend %pagination-block;

            &.next {
                float: right;
            }

            &.numeral {
                @extend %pagination-number;
            }

            &.view_all {
                display: none;
            }
        }

        &_element {
            @extend %pagination-element;

            &.prev {
                @extend %pagination-arrow;
                font-size: 0.8rem;

                @include break(medium){
                  font-size: 1rem;
                }

                &:after {
                    display: none;
                }

                &:before {
                    transition: margin 0.2s ease;
                    content: '→';
                    display: inline-block;
                    font-size: 1.25em;
                    line-height: 1;
                    margin-right: 0;
                    transform: scale(-1, 1);
                    vertical-align: top;

                    @include break(small) {
                        margin-right: 0.35rem;
                    }
                }

                &:hover {
                    &:before {
                        @include break(small) {
                            margin-right: 0.35rem;
                        }
                    }
                }
            }

            &.next {
                @extend %pagination-arrow;
                font-size: 0.8rem;

                @include break(medium){
                  font-size: 1rem;
                }

                &:after {
                    margin-left: 0;

                    @include break(small) {
                        margin-left: 0.35rem;
                    }
                }

                &:hover {
                    &:after {
                        margin-left: 0;

                        @include break(small) {
                            margin-left: 0.35rem;
                        }
                    }
                }
            }

            &.current {
                @extend %pagination-current;
            }
        }
    }
}

@if $drupal {
    .pager {
	    padding: $gap 0;

	    &__items {
	        @extend %pagination;
	    }

        &-first { float: right; }

        &__item {
	        @extend %pagination-block;
			width: 100%;
            @include break(small) {
				width: auto;
            }

	        a {
	            @extend %pagination-element;
				padding: $gap / 2 $gap;
	        }

	        &.is-active {
	            @extend %pagination-element;
	            @extend %pagination-current;

	            a {
		            @include break(small) {
			            padding: 0;
		            }
	            }
	        }

	        &--first a,
	        &--previous a,
	        &--next a,
	        &--last a {
	            @extend %pagination-arrow;
	            @extend %pagination-block;
	        }
        }

        &-ellipsis {
            @extend %pagination-element;
        }
    }
}
