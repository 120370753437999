/* ++++++++++++++++++
+++ LAYOUT: SHARE +++
++++++++++++++++++ */

.share {
	@extend %flex;
	justify-content: center;
	
	&-item {
		margin: 0 $gap;
	}
}