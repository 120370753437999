/* ++++++++++++++++++++
+++ LAYOUT: CONTENT +++
++++++++++++++++++++ */

// Content is the section of the page that contains body copy, typically from a WYSIWYG area. This gets all the post styling such as blockquotes and lists.

.content {
    @extend %font-content;
    line-height: 1.75;

    * {
		margin-top: $gap;

        &:first-child {
	        margin-top: 0;
        }
    }

    // Lists
    ol, ul {
	    margin-top: $gap;
        padding-left: $gap * 1.2;
        line-height: 1.4rem;
    }

    li + li {
        margin: $gap / 2 auto 0;
    }

    ol li {
        list-style: decimal;
 
        ol li { list-style-type: lower-roman; }
        ul li { list-style-type: circle; }
    }

    ul li {
        list-style: disc;

        ul li { list-style-type: circle; }
        ol li { list-style-type: decimal; }
    }

    form {
        ol, ul {
            padding: 0;
        }

        li {
            list-style: none !important;
        }
    }

    p {
        font-size: 1.125rem;
        line-height: 1.6rem;

        &:empty {
            display: none;
        }
    }

    a {
        @extend %text-hover;
        text-decoration: underline;
    }

    // Media
    img {
        // border-radius: $corner;
        margin-top: $gap * 1.5;
    }

    .fluid-width-video-wrapper {
        // border-radius: $corner;
        overflow: hidden;
    }

    a img {
        @extend %image-hover;
    }

    // Buttons
    .btn,
    .button {
        font-size: 1rem;
        margin-top: $gap;

        + .btn, .button {
	        @include break(small) {
		        margin-left: $gap;
	        }
        }
    }

    .btn a,
    .button a,
    a.btn,
    a.button {
        // border: none;
        // box-shadow: none;
        // color: $white;
        text-decoration: none;

        &:hover {
            color: $white;
        }
    }

    p.btn,
    p.button {
        padding: 0;
    }

    .btn a,
    .button a {
        display: block;
        padding: $gap / 2 $gap;
    }

    // Quotes
    blockquote {
		background-color: $grey95;
		// border-radius: $corner;
		margin: $gap * 1.5 0;
		position: relative;

	    p {
		    border-left: solid 6px $secondary;
	        display: block;
	        font-weight: $bold;
	        padding: $gap * 1.5;
	    }
    }

    code {
        background: $grey95;
        // border-radius: $corner;
        border: 1px solid $grey80;
        font-family: $font-meta;
    }

    @include break(small) {
        table, p, li { font-size: 1.125rem; }
        li li { font-size: 1rem; }
    }

    @include break(medium) {
        .alignleft,
        .float-left,
        .float_left {
            float: left;
            margin: $gap * 1.5 $gap * 1.5 $gap * 1.5 0;
            max-width: 48%;
        }

        .alignright,
        .float-right,
        .float_right {
            float: right;
            margin: $gap * 1.5 0 $gap * 1.5 $gap * 1.5;
            max-width: 48%;
        }
    }
}

@if $wordpress {
    .wp-caption {
        position: relative;
        width: auto !important;

	    &-text {
	        @extend figcaption;
	    }
    }
}
@if $drupal {
    figure[role=group] {
        position: relative;
        width: auto !important;

	    figcaption {
	        @extend figcaption;
	    }
    }
}

pre {
	background-color: $white;
	border-radius: 3px;
	font-size: 85%;
	font-family: $font-meta;
	line-height: 1.45;
	margin: $gap * 3 0;
	overflow: auto;
	padding: $gap * 3;
}

p {
	pre {
		display: inline-block;
		padding: 0;
	}
}
