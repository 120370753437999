//++++++++++++++++++++++++
//+++ HELPERS: BUTTONS +++
//++++++++++++++++++++++++

// Button mixin
%button {
    @extend %transition;
    @extend %font-header;
    border-radius: $corner;
    // display: block;
    display: inline-block;
    font-weight: $semibold;
    padding: $gap * 1.15 $gap * 1.85;
    text-align: center;
    // width: 100%;
    width: auto;

    &:after {
        transition: margin 0.2s ease;
        content: '→';
        font-size: 1.25em;
        line-height: 1;
        vertical-align: top;
        margin-left: 0.35rem;
    }

    &:hover {
        &:after {
            margin-left: 0.65rem;
        }
    }

    @include break('small') {
        display: inline-block;
        width: auto;
    }
}

%btn-primary,
%button-primary,
.btn,
.button {
    @extend %button;
    border: 2px solid $primary;
    color: $primary;

    &:hover {
        background-color: $primary;
        color: $white;
    }

    &:active {
        // background: $primary-light;
    }
}

%btn-secondary,
%button-secondary,
.btn-secondary,
.button-secondary {
    @extend %button;
    border: 2px solid $white;
    color: $white;

    &:hover {
        background-color: $white;
        color: $primary;
    }
}

%btn-small,
%button-small,
.btn-small,
.button-small {
    padding: $gap * 0.65 $gap * 1.25;
}

.btn-back {
    padding: 0 !important;
    border: 0;
    &:after {
        display: none;
    }

    &:before {
        transition: margin 0.2s ease;
        content: '→';
        display: inline-block;
        font-size: 1.25em;
        line-height: 1;
        margin-right: 0.35rem;
        transform: scale(-1, 1);
        vertical-align: top;
    }

    &:hover {
    padding: 0 !important;
    border: 0;
    background-color: transparent;
    color: $secondary;
        &:before {
            margin-right: 0.35rem;
        }
    }
}

.button-download {
    vertical-align: bottom;
    .icon {
        height: $gap * 1.35;
        // margin-top: -$gap / 2;
        margin-right: $gap * 0.5;
    }
    &:after,
    &:before {
        display: none;
    }
}

#ccc-dismiss-button span {
    color: #fff !important;
    background-color: inherit !important;
    background: inherit !important;
}