/* +++++++++++++++++++
+++ LAYOUT: DONATE +++
+++++++++++++++++++ */

// The main features of the donate page, such as cost examples and donate form

.donate {
    text-align: center;

    &-options {
        max-width: $third-column;
        margin-top: 0;
    }

    &-label {
        background: $white;
        border: $line solid $secondary;
        color: $secondary;
        cursor: pointer;
        float: left;
        font-weight: $bold;
        margin: 0;
        padding: $gap / 2 $gap;
        width: 50%;

        &:first-child { border-radius: $corner 0 0 $corner; }
        &:last-child { border-radius: 0 $corner $corner 0; }
    }

    &-form {
        background: $white;
        border: $line solid $grey80;
        border-radius: $corner;
        padding-bottom: $gap;
        max-width: $third-column;
    }

    &-header {
        background: $grey95;
        border-bottom: $line solid $grey80;
        border-radius: $corner $corner 0 0;
        padding: $gap;

        &-title {
            @extend %h5;
        }
    }

    &-amount {
        @extend %form-input;
        font-size: 1.4rem;
        padding: 0;
        position: relative;

        &-label {
            left: 0;
            padding: $gap / 2 $gap;
            position: absolute;
        }

        &-input {
            background: none;
            border: none;
            font-weight: $bold;
            margin: 0;
            padding-left: $gap * 2;

            &:placeholder-shown {
                font-weight: $normal;
            }
        }
    }

    &-submit {
        width: 100%;
    }

    @include break(medium) {
        &-form {
            padding-bottom: $gap * 2;
        }
    }
}

#donate-once:checked ~ .donate-options .donate-once,
#donate-monthly:checked ~ .donate-options .donate-monthly {
    background: $secondary;
    color: $white;
}

#donate-once:checked ~ .donate-form .donate-header-title span {
    display: none;
}
