/* ++++++++++++++++++++
+++ LAYOUT: SIDEBAR +++
++++++++++++++++++++ */

body.purple {
	.sidebar {
		background-color: rgba(95, 37, 159, 1);
		color: $white;
		
		&-title {
			color: $white;
			
			@include break(medium) {
				border-bottom: $line solid rgba($white, 0.3);
			}
		}
		&-nav-item {
			&-link {
				&:hover, &.current {
					color: $white;
					text-decoration: underline;
				}
			}
			.sub-list {
				box-shadow: inset 2px 0 rgba($white, 0.3);
				.sidebar-nav-item-link {
					&:hover {
						color: $white;
						text-decoration: underline;
					}
					&.current {
						box-shadow: inset 2px 0 darken($purple, 18);
						color: $white;
						text-decoration: underline;
					}	
				}
			}
			.subnav_element {					
				&:hover, &.current {
					color: $white;
					// font-weight: $bold;
					text-decoration: underline;
				}
			}
		}
	}
}
body.purple30 {
	.sidebar {
		background-color: rgba(95, 37, 159, 0.3);
		color: $black;
		
		&-title {
			color: $black;
			
			@include break(medium) {
				border-bottom: $line solid rgba($white, 0.3);
			}
		}
		&-nav-item {
			&-link {
				&:hover, &.current {
					color: $black;
					text-decoration: underline;
				}
			}
			.sub-list {
				box-shadow: inset 2px 0 rgba($white, 0.3);
				.sidebar-nav-item-link {
					&:hover {
						color: $black;
						text-decoration: underline;
					}
					&.current {
						box-shadow: inset 2px 0 darken($purple, 18);
						color: $black;
						text-decoration: underline;
					}	
				}
			}
		}
	}
}
body.purple60 {
	.sidebar {
		background-color: rgba(95, 37, 159, 0.6);
		color: $black;
		
		&-title {
			color: $black;
			
			@include break(medium) {
				border-bottom: $line solid rgba($white, 0.3);
			}
		}
		&-nav-item {
			&-link {
				&:hover, &.current {
					color: $black;
					text-decoration: underline;
				}
			}
			.sub-list {
				box-shadow: inset 2px 0 rgba($white, 0.3);
				.sidebar-nav-item-link {
					&:hover {
						color: $black;
						text-decoration: underline;
					}
					&.current {
						box-shadow: inset 2px 0 darken($purple, 18);
						color: $black;
						text-decoration: underline;
					}	
				}
			}
		}
	}
}

body.green {
	.sidebar {
		background-color: rgba(80, 158, 47, 1);
		color: $black;
		
		&-title {
			color: $black;	
			
			@include break(medium) {
				border-bottom: $line solid rgba($white, 0.3);
			}
		}
		&-nav-item {
			&-link {
				&:hover, &.current {
					color: $black;
					text-decoration: underline;
				}
			}
			.sub-list {
				box-shadow: inset 2px 0 rgba($white, 0.3);
				.sidebar-nav-item-link {
					&:hover {
						color: $black;
						text-decoration: underline;
					}
					&.current {
						box-shadow: inset 2px 0 darken($lmcgreen, 18);
						color: $black;
						text-decoration: underline;
					}	
				}
			}
		}
	}
}
body.green30 {
	.sidebar {
		background-color: rgba(80, 158, 47, 0.3);
		color: $black;
		
		&-title {
			color: $black;	
			
			@include break(medium) {
				border-bottom: $line solid rgba($white, 0.3);
			}
		}
		&-nav-item {
			&-link {
				&:hover, &.current {
					color: $black;
					text-decoration: underline;
				}
			}
			.sub-list {
				box-shadow: inset 2px 0 rgba($white, 0.3);
				.sidebar-nav-item-link {
					&:hover {
						color: $black;
						text-decoration: underline;
					}
					&.current {
						box-shadow: inset 2px 0 darken($lmcgreen, 18);
						color: $black;
						text-decoration: underline;
					}	
				}
			}
		}
	}
}
body.green60 {
	.sidebar {
		background-color: rgba(80, 158, 47, 0.6);
		color: $black;
		
		&-title {
			color: $black;	
			
			@include break(medium) {
				border-bottom: $line solid rgba($white, 0.3);
			}
		}
		&-nav-item {
			&-link {
				&:hover, &.current {
					color: $black;
					text-decoration: underline;
				}
			}
			.sub-list {
				box-shadow: inset 2px 0 rgba($white, 0.3);
				.sidebar-nav-item-link {
					&:hover {
						color: $black;
						text-decoration: underline;
					}
					&.current {
						box-shadow: inset 2px 0 darken($lmcgreen, 18);
						color: $black;
						text-decoration: underline;
					}	
				}
			}
		}
	}
}
body.blue {
	.sidebar {
		background-color: rgba(0, 169, 224, 1);
		color: $black;
		
		&-title {
			color: $black;
			
			@include break(medium) {
				border-bottom: $line solid rgba($white, 0.3);
			}
		}
		&-nav-item {
			&-link {
				&:hover, &.current {
					color: $black;
					text-decoration: underline;
				}
			}
			.sub-list {
				box-shadow: inset 2px 0 rgba($white, 0.3);
				.sidebar-nav-item-link {
					&:hover {
						color: $black;
						text-decoration: underline;
					}
					&.current {
						box-shadow: inset 2px 0 darken($lmcblue, 18);
						color: $black;
						text-decoration: underline;
					}	
				}
			}
		}
	}
}
body.blue30 {
	.sidebar {
		background-color: rgba(0, 169, 224, 0.3);
		color: $black;
		
		&-title {
			color: $black;
			
			@include break(medium) {
				border-bottom: $line solid rgba($white, 0.3);
			}
		}
		&-nav-item {
			&-link {
				&:hover, &.current {
					color: $black;
					text-decoration: underline;
				}
			}
			.sub-list {
				box-shadow: inset 2px 0 rgba($white, 0.3);
				.sidebar-nav-item-link {
					&:hover {
						color: $black;
						text-decoration: underline;
					}
					&.current {
						box-shadow: inset 2px 0 darken($lmcblue, 18);
						color: $black;
						text-decoration: underline;
					}	
				}
			}
		}
	}
}

body.blue60 {
	.sidebar {
		background-color: rgba(0, 169, 224, 0.6);
		color: $black;
		
		&-title {
			color: $black;
			
			@include break(medium) {
				border-bottom: $line solid rgba($white, 0.3);
			}
		}
		&-nav-item {
			&-link {
				&:hover, &.current {
					color: $black;
					text-decoration: underline;
				}
			}
			.sub-list {
				box-shadow: inset 2px 0 rgba($white, 0.3);
				.sidebar-nav-item-link {
					&:hover {
						color: $black;
						text-decoration: underline;
					}
					&.current {
						box-shadow: inset 2px 0 darken($lmcblue, 18);
						color: $black;
						text-decoration: underline;
					}	
				}
			}
		}
	}
}

// Represents content that is tangentially related to the content nearby, such as tags and subnavigation. within the {% block page %} the sidebar is optional and if included will trigger a two column layout

.sidebar {
	@extend %transition;
	background-color: $grey95;
	// border-radius: $corner;
	margin-bottom: $gap;
	padding: $gap $gap * 1.5;
    width: 100%;
	line-height: 1.6rem;

	&-summary {
		margin-top: $gap / 2;
	}

    .sidebar-details {
    	border-top: 1px solid $grey70;
    	font-size: 1rem;
    	margin: $gap 0;
    	padding-top: $gap * 1.25;
    	// font-weight: $bold;

    	.sidebar-detail {
		    @extend %font-label;

    		&:not(:last-of-type) {
        		margin-bottom: $gap * 0.75;
    		}
        	// list-style-type: disc;
    		// margin-left: $gap * 1.25;

    		span {
    			@extend %font-body;
    			display: block;
    			font-size: 1.1rem;
    			letter-spacing: 0;
    			text-transform: none;
				
				a {
					@extend %transition;
					text-decoration: underline;
					
					&:hover {
						color: $secondary;
					}
				}
    		}
    	}
    }

    .button {
    	@extend %btn-small;
    	margin-top: $gap * 0.5;
    }

    .sidebar-button + .sidebar-button {
    	.btn-back {
	    	margin-top: $gap;
	    }
    }

    iframe {
    	height: $gap * 20;
    	margin: (-$gap * 1.5) auto $gap (-$gap * 1.5);
    	width: calc(100% + 3rem);
    }

    @include break(medium) {
	    @include flexbox(29%);
		margin: -2rem 0 0;
        float: right;
        margin: 0;
		padding: $gap * 1.5;
		//position: sticky;
		//position: -webkit-sticky;
		top: calc(#{$header-height} + (#{$gap} * 2));


		.nav-up &{
			top: $gap * 2;
		}
    }
    @include break(large) {
	    @include flexbox(25%);
    }

    &-block + &-block {
        margin-top: $gap * 3;
    }

    &-title {
        color: $primary;
        font: $bold 1.2rem/1.2 $font-header;
        margin: 0;

        @include break(medium) {
	        border-bottom: $line solid $grey80;
	        margin: 0 0 $gap;
			padding-bottom: $gap / 2;
        }
    }

    &-block {
	    position: relative;

	    &.expand {
		    &:before {
			    content: '';
			    display: none;
			    height: 30px;
			    left: 0;
			    position: absolute;
			    top: 0;
			    width: 100%;
			    z-index: 2;

			    @include break(medium) {
				    display: block;
			    }
		    }
	    }

	    .icon {
		    position: absolute;
		    right: 0;
		    top: 0;

		    @include break(medium) {
		    	display: none;
		    }
	    }
		.expand-check ~ div {
		    display: none;

		    @include break(medium) {
		    	display: block !important;
		    }
		}
    }

    &-nav {
	    border-top: solid 1px $grey90;
	    margin-top: $gap;
	    padding-top: $gap;

	    @include break(medium) {
		    border-top: 0;
		    margin: 0;
		    padding-top: 0;
	    }

        &-item {
	        padding: 0 0 $gap / 2;

	        &-link {
	        	@extend %transition;

	        	&:hover {
	        		color: $black;
					text-decoration: underline;
	        	}
	        }

            .subnav_element {
                @extend %text-hover;
                display: block;
                // font-weight: $bold;

                &:hover, &.current {
                    color: $black;
                    // font-weight: $bold;
					text-decoration: underline;
                }
            }

            &.current-menu-item {
                .subnav_element {
                    color: $black;
                    // font-weight: $bold;
					text-decoration: underline;
                }

                .sidebar-nav-item {
                    .subnav_element {
                        color: black;
                        // font-weight: $normal;
						text-decoration: underline;
                    }
                }
            }
        }
    }

    &-meta {
	    margin: 0;
	    position: relative;

	    &-block {
		    padding: $gap / 2 0;
		    span {
			    color: $secondary;
			    font-weight: $bold;
		    }
		    &-tag {
		        @extend %transition;
		        background: $white;
		        border-radius: $corner;
		        color: $grey40;
		        display: inline-block;
		        font: $bold 0.9rem/1.5 $font-body;
		        margin: 0 $gap / 2;
		        padding: $gap / 4 $gap / 2;

		        &:hover {
		            background: $secondary;
		            color: $white;
		        }
		    }
	    }
	    + .sidebar-img {
		    img {
			    padding-top: 0;
		    }
	    }
	    + .sidebar-button {
		    margin-top: $gap;
	    }
    }
    .inline-group {
	    + .sidebar-button {
		    margin-top: $gap;
	    }
    }
    &-downloads {
	    margin-top: $gap;

	    &-block {
		    background-color: $white;
		    color: $black;
		    padding: $gap;
		    width: 100%;

		    .icon {
			    display: inline-block;
			    margin: 0 $gap / 3 0 0;
			    width: 20px;
		    }

		    h3 {
			    color: $primary;
			    font-size: 1.2rem;
		    }
		    a {
			    @extend %transition;
			    display: block;
			    font-size: 0.9rem;
			    padding: $gap / 2 0 0 $gap * 2;
				position: relative;

			    &:hover {
				    color: $primary;
			    }

			    .icon {
				    left: 0;
				    position: absolute;
				    top: $gap / 2;
			    }
		    }
	    }
    }
}

.sub-list {
    box-shadow: inset 2px 0 $grey90;
    margin: $gap 0 0;

    & & {
        margin-left: $gap;
    }

    .sidebar-nav-item-link {
	    display: block;
		font-size: 1.1rem;
	    font-weight: $normal;

        &.current {
            box-shadow: inset 2px 0 currentColor;
            color: $secondary;
			margin-left: -$gap;
			padding-left: $gap;
        }
    }

    .sidebar-nav-item {
		padding-left: $gap;
		
		.sub-list {
			.sidebar-nav-item-link {
				font-size: 1rem;
			}
		}

        &.current-menu-item {
            .sidebar-nav-item-link {
                box-shadow: inset 2px 0 currentColor;
            }

            .sidebar-nav-item {
                .sidebar-nav-item-link {
                    box-shadow: none;
                }
            }
        }
    }
}
