/* +++++++++++++++++
+++ LAYOUT: FEED +++
+++++++++++++++++ */

// Feed is the basic listing of content such as news posts, more scannable and content heavy than cards

.feat-items {
	.item {
        @extend %transition;
        border-top: solid 1px $grey90;
        border-left: solid 5px $secondary;
		background-color: $white;
		// border-radius: $corner;
        box-shadow: $shadow;
		padding-bottom: 0;

        &:hover {
            // box-shadow: $shadow-strong;
        }

		&-image {
			// border-radius: $corner $corner 0 0;
		}
		&-info {
			padding: $gap * 1.5;
		}

		+ .item {
            border-top: solid 1px $grey90;
            border-left: solid 5px $secondary;
			margin-top: $gap * 2;
			padding-top: 0;
		}
	}

	@include break(medium) {
		.item {
			&-image {
				// border-radius: 0 $corner $corner 0;
			}
		}
	}
}

.item {
	@extend %flex;
	flex-direction: row-reverse;
	padding-bottom: $gap * 2;

    // .card-details {
    //     border-bottom: 1px solid $grey70;
    //     font-size: 1rem;
    //     list-style: none;
    //     margin: $gap 0;
    //     padding-bottom: $gap;
    //     // font-weight: $bold;

    //     .card-detail {
    //         display: inline;
    //         @extend %font-label;

    //         &:not(:first-of-type) {
    //             margin-bottom: $gap * 0.75;

    //             &:before {
    //                 content: '|';
    //                 display: inline-block;
    //                 margin: 0 $gap / 2.5;
    //             }
    //         }
    //         // list-style-type: disc;
    //         // margin-left: $gap * 1.25;

    //         span {
    //             display: inline;
    //             font-size: 1.1rem;
    //             font-family: $font-body;
    //             font-weight: $normal;
    //             // margin-left: - $gap * 1.25
    //         }
    //     }
    // }

            .card-details {
                border-bottom: 1px solid $grey70;
                font-size: 1rem;
                margin: $gap 0;
                padding-bottom: $gap * 1.25;
                // font-weight: $bold;

                .card-detail {
                    display: inline-block;
                    @extend %font-label;

                    &:not(:last-of-type) {
                        margin-right: $gap * 2;
                    }
                    // list-style-type: disc;
                    // margin-left: $gap * 1.25;

                    span {
                        @extend %font-body;
                        display: block;
                        font-size: 1.1rem;
                        letter-spacing: 0;
                        text-transform: none;
                    }
                }
            }

    &:hover {
        .item-image {
            img {
                transform: scale(1.05);
            }
        }

        .item-header-title {
            color: $secondary;
        }
    }

	@include break(medium) {
		padding-bottom: 0;
	}

    & + & {
        border-top: $line solid $grey80;
        padding-top: $gap * 2;
    }

    &-image {
	    @include flexbox(100%);
        @extend %image-hover;
        // border-radius: $corner;

        img {
			@extend %object-fit;
        }
    }

    &-info {
		@include flexbox(100%);
	    margin: 0;
    }

    &-link {
        @extend %text-hover;
    }

    &-header {
        margin-top: $gap / 2;

        &-title {
            margin-bottom: $gap;

            a {
                @extend %text-hover;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-date, &-author {
            display: inline-block;
            font-family: $font-header;
            font-size: 1rem;
            margin: $gap / 2 0;

            a {
                @extend %transition;

                &:hover {
                    color: $secondary;
                }
            }
        }
        &-author {
	        &:before {
		        content: '|';
		        display: inline-block;
		        margin: 0 $gap / 2;
	        }
        }
    }

    &-summary {
        margin-left: 0;
        max-width: $half-column;
        line-height: 1.6rem;
    }

    @include break(tablet) {
        & + & {
            margin-top: $gap * 2;
            padding-top: $gap * 2;
        }

		&-info {
			// @include flexbox(65%);
			padding-right: $gap * 1.5;
		}

        &-image {
	        @include flexbox(35%);

            & + * {
                margin-top: 0;
            }
        }
    }
}
