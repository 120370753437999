//++++++++++++++++++++++
//+++ HELPERS: FORMS +++
//++++++++++++++++++++++

%form-input {
    @extend %transition;
    color: $grey20;
    border-radius: $corner;
    // border: $line solid $grey80;
    font: $normal 1rem/1.5 $font-header;
    height: 2.5rem;
    padding: 0 $gap;
    width: 100%;

    &:disabled {
        background: $grey90;
    }
}

form {
    font: 1rem $font-body;

    p { font: 1rem $font-body; }
}

input,
textarea,
select {
    @extend %form-input;
}

input {
    // font-family: $font-body;
    font-weight: $semibold;
    height: $gap * 3.5;
    padding: 0 $gap * 1.6;
    width: 100%;
    max-width: $gap * 17.5;
    margin-top: 0;

    @include break(medium) {
        min-width: $gap * 15;
    }
}

[type="text"],
[type="number"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="time"],
[type="search"],
[type="tel"],
[type="url"],
[type="email"],
[list],
textarea,
select {
    background: $white;
    outline: none;

    &:focus {
        border-color: $primary;
    }

	&::-webkit-input-placeholder {
	  color: $grey50;
	  opacity: 1;
	}
	&::-moz-placeholder {
	  color: $grey50;
	  opacity: 1;
	}
	&:-ms-input-placeholder {
	  color: $grey50;
	  opacity: 1;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: $grey50;
	  opacity: 1;
	}
}

textarea {
    resize: vertical;
    padding: $gap / 2 $gap;
    height: auto;
    min-height: $gap * 8;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    @extend .button;
    -webkit-appearance: none;
    border: none;
    cursor: pointer;
}

[type="color"],
[type="file"] {
    border: none;
    line-height: 1;
    padding: 0;
}

[type="file"] {
    height: auto;
}
select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-image: url('../images/select-arrow-2-purple.svg');
	background-position: 95% center;
	background-repeat: no-repeat;
	background-size: $gap * 2;
	border: solid 1px #A1AEB7;

	@include break(small) {
		background-image: url('../images/select-arrow-2-purple.svg');
		background-position: 98% center;
		background-repeat: no-repeat;
	}
	&::-ms-expand {
	    display: none;
	}
}

label {
    font: $normal 1rem/1.5 $font-body;
    display: block;

    // & + input,
    // & + textarea,
    // & + select { margin-top: $gap / 4; }
}

legend {
    border-bottom: $line solid $grey90;
    padding-bottom: $gap / 2;
    width: 100%;
}

[type="checkbox"],
[type="radio"] {
    float: left;
    height: $gap * 1.5;
    margin-right: $gap / 2;
    width: auto;

    & + label {
        display: inline;
        font-style: normal;
    }
}

.select2-dropdown {
    // border-radius: 10px !important;
    border: 1px solid $grey50 !important;
    margin-top: 0 !important;
    overflow: hidden !important;
}

.select2-selection--multiple {
    border: none !important;
}

.select2-results__option--highlighted {
    // background-color: $blue !important;
    color: $white !important;
}

.select2-selection__rendered {
    @extend %transition;
    padding: 0 $gap !important;
    border: 2px solid $purple;
    background-position: 94% center;
    color: $grey20;
    border-radius: 10px;

    // background-image: url('../images/select-arrow-2-purple.svg');
    // background-repeat: no-repeat;
    // background-size: $gap * 2;
    
    // @include break(small) {
    //     background-position: 98% center;
    // }
}

.select2-selection__choice {
    background-color: $purple !important;
    padding: $gap * 0.35 $gap * 0.5 !important;
    color: $white !important;
    border: none !important;
}

.select2-selection__choice__remove {
    margin-right: 5px !important;
    color: $white !important;
}

@if $wordpress {
    .gform_body {
		.ginput_container {
			* {
				margin-top: 0;
			}
			.gfield_checkbox {
				input[type=checkbox] {
					margin-top: 0 !important;
				}
			}
		}
        select + label,
        input[type="text"] + label {
            color: $grey50;
            font-style: italic;
            margin: 0 auto $gap;
        }

        label + * {
            margin-top: $gap / 4;
        }

        li.gfield + li.gfield {
            margin-top: $gap * 2;
        }

        .gfield_required {
            color: $red;
        }
        
        .screen-reader-text {
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        input,
        textarea {
            border: 2px solid $purple;
        }

        textarea {
            border-radius: 25px;
            resize: none;
            padding: 1rem 1.6rem;
        }

        input {
            max-width: $gap * 17.5;

            @include break(medium) {
                min-width: $gap * 25;
            } 
        }

        select {
            // background-color: transparent;
            // color: $white;
            background-position: 94% center;
            border: 2px solid $purple;
            // font-family: $font-body;
            font-weight: $semibold;
            height: $gap * 3.5;
            padding: 0 $gap * 1.6;
            width: 100%;
            max-width: $gap * 17.5;

            &.has-selection {
                &+span .select2-selection__rendered {
                    padding-bottom: 5px !important;
                    padding-right: 40px !important;
                    padding-top: 6px !important;
                }
            }

            @include break(medium) {
                min-width: $gap * 25;
            }
        }
        
        .ginput_container_select {
            height: 3.5rem;
        }
        
        .select2-selection--single {
            // height: 3.5rem;
            // padding: 1.6rem 0;
        }
        
        .select2-dropdown--below {
            margin-top: 10px !important;
        }
        
        .select2-selection--single {
            border: 0 !important;
        }
    }

    .gform_footer {
        .button {
            // margin-top: $gap * 1.5;
            background-color: $purple;
            color: $white;
            height: $gap * 3.5;
            width: auto;
            min-width: $gap * 10;

            @include break(medium) {
                // min-width: $gap * 12.5;
            }

            @include break(small) {
                // margin-top: 0;
            }

            &:hover {
                background-color: $primary;
                color: $white;
            }            
        }
    }
}

.ginput_container_consent {
    input[type=checkbox] {
        min-width: 1rem !important;
    }
}

.select2-dropdown {
    border-radius: 10px !important;
}

.select2-search__field{
    border-radius: 10px !important;
    height: 3.5rem;
    padding-left: 1rem;
    min-width: 100% !important;
}

.select2-selection__rendered {
    padding: 0 1.6rem;
    padding-top: 10px !important;
}

.select2-selection__arrow {
    height: 3rem !important;
}

.select2-dropdown--below {
    margin-top: 1.6rem;
}

.select2-dropdown--above {
    top: 2px;
}

