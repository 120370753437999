/* ++++++++++++++++++++
+++ LAYOUT: FILTERS +++
++++++++++++++++++++ */

.filter {
	@extend %flex;
    align-items: center;
    justify-content: center;

    &-block {
        flex: 0 100%;
        text-align: center;

        &:not(:last-of-type) {
            margin-bottom: $gap;

            @include break(medium) {
                margin-bottom: 0;
            }
        }

        @include break(medium) {
	        flex: 0 auto;
            margin: 0 $gap * 1 0;
        }

        label {
            flex-basis: auto;
            font-size: 1.5rem;
            font-weight: $extrabold;
            margin-right: 0;

            @include break(medium) {
                text-align: right;
            }
        }

        select {
            background-position: 94% center;
            border: 2px solid $purple;
            font-weight: $semibold;
            height: $gap * 3.5;
            padding: 0 $gap * 1.6;
            width: 100%;
            max-width: $gap * 17.5;

            @include break(medium) {
                min-width: $gap * 15;
            }
        }
    }

    &-submit {
        background-color: $purple;
        color: $white;
        height: $gap * 3.5;
        width: auto;
        max-width: $gap * 17.5;

        &:hover {
	        background-color: $primary;
	        color: $white;
        }
    }
}

.filter-block {
    .select2-selection {
        @extend %form-input;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        background-image: url('../images/select-arrow-2-purple.svg') !important;
        background-position: 94% center !important;
        background-repeat: no-repeat !important;
        background-size: $gap * 2 !important;
        background-color: $white !important;
        border-radius: $corner !important;
        border: 2px solid $purple !important;
        // border: 0;
        font-weight: $semibold !important;
        height: $gap * 3.5 !important;
        margin: 0 !important;
        max-width: $gap * 17.5 !important;
        outline: none !important;
        padding: 0 $gap * 1.6 !important;
        text-align: left !important;
        width: 100% !important;

        @include break(medium) {
            min-width: $gap * 15 !important;
        }

        &:focus {
            border-color: $primary;
        }

        &::-webkit-input-placeholder {
            color: $grey50;
            opacity: 1;
        }

        &::-moz-placeholder {
            color: $grey50;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: $grey50;
            opacity: 1;
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: $grey50;
            opacity: 1;
        }

        @include break(small) {
            background-image: url('../images/select-arrow-2-purple.svg') !important;
            background-position: 94% center !important;
            background-repeat: no-repeat !important;
        }

        &::-ms-expand {
            display: none;
        }
    }

    .select2-container--default .select2-results__option[aria-disabled=true] {
        display: none !important;
    }

    .select2-selection__rendered {
        padding: $gap * 0.75 !important;
        border: 0 !important;
    }

    .select2-dropdown  {
        border-radius: 6px !important;
        overflow: hidden !important;
        border: 2px solid $purple !important;
    }

    .select2-results__option--highlighted {
        background-color: $purple !important;
    }

    .select2-selection__arrow {
        display: none !important;
    }
}