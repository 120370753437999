//+++++++++++++++++++++++++
//+++ HELPERS: WEBFONTS +++
//+++++++++++++++++++++++++

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: optional;
    src: url('../webfonts/opensans-regular.woff2') format('woff2'),
        url('../webfonts/opensans-regular.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: optional;
    src: url('../webfonts/opensans-italic.woff2') format('woff2'),
        url('../webfonts/opensans-italic.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: optional;
    src: url('../webfonts/opensans-bold.woff2') format('woff2'),
        url('../webfonts/opensans-bold.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-display: optional;
    src: url('../webfonts/opensans-bolditalic.woff2') format('woff2'),
        url('../webfonts/opensans-bolditalic.woff') format('woff');
}