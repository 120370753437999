/* +++++++++++++++++++
+++ LAYOUT: HEADER +++
+++++++++++++++++++ */

// Site header bar
.header {
	@extend %flex;
    background: $header-color;
    position: relative;
    width: 100%;
    z-index: map-get($zindex, header);
    height: auto !important;

    &-inner {
	    @extend %flex;
	    @include flexbox(100%);
	    // justify-content: space-between;
	    padding: $gap * 0.75 0;

        @include break(medium) {
            padding: 0 0 $gap * 0.75;
        }
    }

    &-logo {
	    margin-left: 0;
        max-width: 250px;

        @include break(small) {
            max-width: 300px;
        }

        @include break(medium) {
            position: absolute;
            top: $gap;
            max-width: 350px;
            // margin-top: -2.25rem;
        }

        @include break(large) {
            top: $gap * 1.5;
        }
    }

    &-links, {
        display: none;
     	margin-right: 0;

		@include break(medium) {
            display: block;
		}
    }

    &-nav {
        @extend %flex;
        font-family: $font-header;
	    margin-right: 0;
        text-transform: uppercase;

        @include break(medium) {
            @include flexbox(100%);
            padding-top: $gap * 1.75;
            justify-content: center;
        }

        @include break(large) {
            @include flexbox(auto);
            padding-top: 0;
            // justify-content: flex-end;
            // display: block;
        }

	    &-list {
	        display: none;
            font-weight: $bold;

            .nav-item-link {
                margin-bottom: $gap / 4;
                padding-bottom: $gap / 6;
                
                &.current {
                    box-shadow: inset 0 -2px 0 $secondary;
                }
            }

		    @include break(medium) {
		        display: block;
		        margin: 0;
		    }
	    }
    }

    &-subnav {
	    display: none;
        padding-top: $gap * 1.5;

        .subnav-item {
            .nav-item-link {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            &:last-of-type {
                @extend %transition;

                .nav-item-link {
                    transition: 0;
                }
            }
        }

	    @include break(medium) {
		    @include flexbox(100%);
		    display: block;

		    &-list {
			    text-align: right;

			    .subnav-item {
				    display: inline-block;

				    .nav-item-link {
                        display: inline-block;
					    font-size: 0.9rem;
					    margin: 0 0.75rem;

                        &:hover {
                            // text-decoration: underline;
                        }
				    }

                    &:last-of-type {
                        border: 0.5px solid $secondary;
                        border-radius: $corner;
                        color: $secondary;
                        margin: 0 0.75rem;
                        padding: 0 0.75rem;

                        &:hover {
                            background-color: $secondary;
                            color: $white;

                            .nav-item-link {
                                color: $white;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }

                        .nav-item-link {
                            margin: 0 0.15rem;
                        }
                    }
			    }
		    }
	    }

        @include break(large) {
            padding: $gap 0 $gap / 2;
        }
    }

	&-search {
	    display: none;

		@include break(medium) {
		    display: inline-block;

		    &-input {
		        @include transition-ease(width, opacity, height);
		        background: transparent;
		        border: none;
		        display: none;
		        font-size: 1rem;
		        height: 0;
		        opacity: 0;
		        padding: 0;
		        text-align: left;
		        width: 0;

		        &.active,
		        &:focus {
		            display: inline-block;
					height: 1.8rem;
					margin-top: 0;
					opacity: 1;
					width: 100%;
		        }

		        &:placeholder-shown {
		          text-align: left;
		          width: auto;
		        }
		    }
		}
	}
}

// Navigation items in the header and footer
.nav-item {
    float: left;

    &:hover .dropdown {
        @include transition(opacity 0.2s 0.3s ease);
        opacity: 1;
        visibility: visible;

        .nav-item {
            float: none;
        }
    }

    .button {
        margin-left: $gap;
    }

    &-link {
        @extend %text-hover;
        display: block;
        font-size: 0.95rem;
        margin: 0 0.75rem;
        padding: $gap / 2 0;

        &:hover {
            // color: $secondary;
        }

        .social & {
            margin: 0;
            padding: $gap / 2 $gap;
        }
    }

    &-arrow {
        width: $gap;
    }
}

// Responsive nav hamburger button
.menu-button {
    @extend %flex;
    align-items: center;
    color: $secondary;
    cursor: pointer;
    font-size: 1rem;
    font-weight: $bold;
    margin: $gap / 5 0 0 0;
    padding: 0 $gap * 1.5 0 0;
    position: relative;
    text-transform: uppercase;

    @include break(small) {
        font-size: 1.4rem;
    }

    span {
        display: none;

        @media (min-width: 370px) {
            display: inline-block;
        }
    }

    @include break(medium) {
        display: none;
    }
    .icon {
	    @include centerer(false, true);
	    height: 1rem;
		right: 0;
	    width: 1rem;
    }
}

// Search trigger
.search-open {
    background: none;
    color: $grey20;
    cursor: pointer;
    margin: 0 !important;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
}

// Dropdown menu styling
.dropdown {
    @include transition(visibility 0s 0.4s ease, opacity 0.2s 0.2s ease);
    background: $header-color;
    box-shadow: $shadow;
    // border-radius: 0 0 $corner-subtle $corner-subtle;
    margin-top: 12px;
    opacity: 0;
    position: absolute;
    text-transform: none;
    visibility: hidden;

    &-item {
        .nav-item-link {
            font-weight: $normal;
            margin: 0 $gap;
            padding: $gap * 0.35 0;
        }
        &:first-of-type {
            .nav-item-link {
                padding-top: $gap * 0.25;
            }
        }

        &:last-of-type {
            .nav-item-link {
                padding-bottom: $gap * 0.75;
            }
        }
    }
}

// CMS tweaks
@if $wordpress {
    #wpadminbar {
        box-sizing: content-box;
        margin-top: 0;

        img {
            display: inline;
        }

        @media screen and (max-width: 600px) {
            position: fixed;
        }
    }
}

@if $drupal {
	.header-nav-outer {
		.nav-item-link {
			&.is-active {
		      color: $grey15;
		      font-weight: $bold;
			}
		}
	}
}
