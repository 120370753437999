/* ++++++++++++++++++++
+++ LAYOUT: SECTION +++
++++++++++++++++++++ */

// A section is a grouping of content, typically with a header, possibly with a footer. A '.section' with a '.container' results in a consistent paddings, margins and max-width for content while allowing for full width background colors on the section and centred background colors on the container.

.section {
    @extend %section;

    &.section-filter {
        padding: $gap * 3 0;
    }

    .section-filter {
        margin-top: $gap * 2;
    }

    &.narrow {
        .container {
            max-width: $two-thirds-column;
        }
    }

    &.dark,
    &.primary,
    &.secondary,
    &.green {
        color: $white;

        input,
        textarea,
        select {
            border-color: transparent;
        }

        .section-header {
          
            &:before {
                border-color: $white;
            }
        }

        .button {
            @extend %btn-secondary;
        }
    }

    &.white { background: $white; }
    &.grey { background: $grey90; 
	    + .feat-items {
		    .section {
			    &.grey {
				    padding-top: 0;   
			    }   
		    }
	    }
    }
    &.dark { background: $grey15; }
    &.primary { background: $primary; }
    &.secondary { background: $secondary; }
    &.green { background: darken($green, 7.5%)};

    &.secondary,
    &.green,
    &.purple {
        .filter-submit {
            border: none;

            &:hover {
                background-color: $primary;
                color: $white;
            }
        }
    }

    &.bg-river {
        background-image: url(../svg/river-sm.svg);
        background-color: #C2DAED;
        // background-position: 8% 8%;
        background-size: contain;
        background-repeat: no-repeat;
        background-size: 100%;
        position: relative;

        @include break(medium) {
            background-image: url(../svg/river-md.svg);
        }

        @include break(large) {
            background-image: url(../svg/river-lg.svg);
        }

        &:before {
            // content: '';
            // border: 1px solid red;
            // height: 10rem;
            // background-color: $white;
            // width: 100%;
            // position: absolute;
            // top: 0;
        }
        
        &.white {
            .section-header-cta {
                .button {
                    background-color: $white;
                
                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                }                
            }
        }
    }

    &.bg-map {
        background-image: url(../images/map.jpg);
        // background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        // background-size: 100%;

        @include break(medium) {
            padding: $gap * 5 0;
        }

        .container {
            @extend %flex;
            align-items: center;
        }

        .box {
            display: inline-block;
            margin-left: 0;
            line-height: 1.8rem;

            a {
                text-decoration: underline;
            }

            &-title,
            &-summary {
                margin-left: 0;
            }

            &-title {
                position: relative;
            }

            &-title + p {
                margin-top: $gap * 1;
            }
            
            &-summary {
                margin-top: $gap * 1;

                p {
                    &:not(:last-of-type) {
                        margin-bottom: $gap * 2;
                    }

                    a {
                        @extend %transition;

                        &:hover {
                            color: $green;
                        }
                    }
                }
            }

            &.box-text,
            &.box-select {
                padding: $gap * 3;
            }

            &.box-text {
                background-color: $white;

                @include break(medium) {
                    padding: $gap * 5 20% $gap * 5 $gap * 5;
                    width: 65%;
                }

                @include break(xlarge) {
                    padding: $gap * 5 20% $gap * 5 $gap * 8;
                }

                .box-title {
                    padding-top: $gap * 2;

                    &:before {
                        border-top: 3px solid $primary;
                        content: '';
                        width: 10rem;
                        position: absolute;
                        top: 0;
                    }
                }
            }

            &.box-select {
                background-color: $primary;
                color: $white;
                height: auto;
                width: 100%;

                .box-title {
                    @extend %h5;
                }

                @include break(medium) {
                    padding: $gap * 4 $gap * 3;
                    margin-left: -10%;
                    width: 45%;
                }

                @include break(medium) {
                    // padding: $gap * 5 $gap * 4;
                }

                @include break(large) {
                    padding: $gap * 5;
                }

                .filter {
                    padding: 0;
                    margin-top: $gap * 1.15;
                    justify-content: flex-start;

                    &-block {
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        text-align: left;

                        .filter-submit {
                            margin-top: $gap;
                            min-width: 8rem;

                            &:hover,
                            &:focus,
                            &:active {
                                background-color: $green;
                            }
                        }
                    }

                    &-field {
                        background-color: transparent;
                        border: 2px solid $green;
                        color: $white;
                        height: $gap * 3.5;
                        padding: 0 $gap * 1.6;
                        min-width: 100%;
                        background-image: url('../images/select-arrow-2.svg');

                        @include break(small) {
                            background-image: url('../images/select-arrow-2.svg');
                        }
                    }
                }

                .box-summary {
                    margin-top: $gap * 1.5;
                    font-size: 1rem;
                }

                p {
                    // font-family: $font-header;
                    // font-size: 1rem;
                }
            }
        }
    }

    &.subnav {
	    background: $white;
    }

    &.sec-links {
	    border-bottom: solid 2px rgba($primary, 0.05);
	    padding-bottom: $gap * 1.5;
	    padding-top: $gap * 1.5;

	    .container {
		    max-width: none;
			padding: 0;
	    }
	    &-outer {
		    padding-bottom: 0;

		    .container {
			    padding: 0;
		    }
	    }
    }

    &-header {
        @extend %flex;
        // text-align: center;
        margin-bottom: $gap * 3.5;

        &-title {
            @extend %h3;
	        + .section-header-summary {
		        padding-top: $gap / 1.5;
                line-height: 1.6rem;
	        }
        }

        &-summary {
            // font-size: 1.25rem;
            line-height: 1.6rem;
        }

        &-cta {
            margin-left: 0;
            margin-top: $gap * 1.5;

            @include break(tablet) {
                margin-top: 0;
            }
        }

        @include break(tablet) {
            &:before {
                @include flexbox(12.5%);
                border-top: 4px solid $primary;
                content: "";
                text-align: right;
                // padding-left: 5%;
                margin-top: 1.25rem;
                margin-left: 10%;
                // padding-right: 1rem;
                // max-width: 10rem;
            }

            .section-header-info {
                @include flexbox(40%);
                margin-left: 2%;
                // padding-left: 1%;
            }

            .section-header-cta {
                @include flexbox(35.5%);
                display: inline-block;
                text-align: right;
            }
        }

        @include break(medium) {
            &:before {
                .bg-image & {
                    // @include flexbox(25%);
                    margin-left: 50%;
                }
            }

            .section-header-info {
                .bg-image & {
                    @include flexbox(24%);
                }
            }
        }
    }

    &-footer {
        @extend %flex;
        @extend %container;
        margin-top: $gap;
        text-align: right;

        .button {
            // margin-right: 2rem;
        }

        @include break(small) {
	        margin-top: $gap * 2;
        }
    }

    &.inline-section {
	    .section-header {
		    @include break(medium) {
			    text-align: left;
		    }
	    }
	    .accordion-outer, .pullquote {
		    @include break(medium) {
			    margin-left: 0;
			    width: 66.66%;
		    }
	    }
	    .pullquote {
		    &-image {
				+ .pullquote-info {
					blockquote {
						font-size: 1.3em;
					}
				}
		    }
		    cite {
			    font-size: 1em;
		    }
	    }
    }
}

.white + .white,
.grey + .grey,
.dark + .dark,
.secondary + .secondary,
.green + .green,
.primary + .primary, {
    padding-top: 0;
}

.container {
    @extend %container;

    &.centered { text-align: center; }
    &.small { max-width: $two-thirds-column; }

    &.white,
    &.grey,
    &.dark,
    &.primary,
    &.secondary {
        padding: $gap;

        @include break(small) {
            padding: $gap * 2;
        }

        @include break(large) {
            border-radius: $corner;
            max-width: $page-width - $gap * 4;
        }
    }

    &.dark,
    &.primary,
    &.secondary {
        color: $white;
    }

    &.white { background: $white; }
    &.grey { background: $grey90; }
    &.dark { background: $grey15; }
    &.primary { background: $primary; }
    &.secondary { background: $secondary; }
}

//general listing

.general-listing {
    .section {
        &.grey {
            background: black;
        }

        .main {
            &-header {
                max-width: 80rem;

                &-title {
                    color: white;
                    text-align: left;
                }
            }
        }

        &-header {
            margin-bottom: 2rem;
            
            &:before {
                content: unset;
            }

            &-info {
                margin-left: 0;
                @include flexbox(100%);
                padding: 1rem;

                @include break(tablet) {
                    @include flexbox(80%);
                    padding: 0rem;
                }
            }
        }
    }
}