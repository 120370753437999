/* +++++++++++++++++++++++
+++ LAYOUT: STATISTICS +++
+++++++++++++++++++++++ */

.statistic {
	@extend %flex;
	padding: 0 $gap * 2;
	justify-content: space-between;
    text-align: center;
    
    @include break(large) {
	    padding: 0;
    }
    
    &-item {
    	@include flexbox(100%);
    	margin: 0 0 $gap * 2;
    	
    	@include break(tablet) {
	    	@include flexbox(48%);
    	}
    	
    	@include break(medium) {
	    	@include flexbox(20%);
			margin: 0;
	    	
		    &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ .statistic-item {
	            @include flexbox(48%);
		    }
		    &:first-child:nth-last-child(3), &:first-child:nth-last-child(3) ~ .statistic-item {
	            @include flexbox(30%);
		    }
    	}
    	&:last-child {
	    	margin-bottom: $gap;
	    	
	    	@include break(small) {
	    		margin: 0 0 $gap * 2;
	    	}
    	}
    }

    &-icon {
        font-size: 2rem;
    }

    &-value {
		border-bottom: solid 10px $secondary;
	    display: inline-block;
	    padding: 0 0 $gap / 2;
	    text-align: center;
	    
        &-number {
          font-size: 4rem;
		  font-weight: $bold;
        }
    }
    &-title {
		font-size: 1.6rem;
		padding: 1.5rem 0 1rem;
		position: relative;
    }
}
