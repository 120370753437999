/* +++++++++++++++++
+++ LAYOUT: MAIN +++
+++++++++++++++++ */

// Main is the high level container of content, in between the site header and footer

.main {
    background: $body-color;
    display: block;
    margin: 0;

    &.purple {
        .section:first-child {
            background-color: $purple;
            color: $white;
        }
    }

    &.green {
        >.section:first-child {
            background-color: $green;
            color: $white;
        }
    }

    &-section {
        @extend %flex;
        @extend %section;
        align-items: flex-start;
        margin: auto;
        max-width: $page-width;
        overflow: visible;
    }

    &-header,
    &-footer {
        padding-top: 0;
        text-align: center;
    }

    &-header {
        &-title {
            @extend %h2;
            margin-bottom: $gap * 0.75;
        }

        &-summary {
            line-height: 1.6rem;
            p {
                font-size: 1.2rem;
            }
        }
    }

    @include break(medium) {
        &-section {
            flex-flow: row wrap;
            > * {
                flex: 1 66.66%;
            }
        }
    }
    
    &-share {
      &-outer {
        background-color: $secondary;
        border-left: solid 5px $grey;
        box-shadow: $shadow;
        display: inline-block;
        opacity: 0;
        max-width: 72px;
        padding: $gap $gap $gap / 1.5 $gap;
        position: fixed;
        right: 0;
        top: $gap * 5;
        transition: visibility 0s 1s, opacity 1s linear;
        visibility: hidden;
        width: 72px;
        z-index: 45;
    
        &.share-fix {
          opacity: 1;
          transition: opacity 1s linear;
          visibility: visible;
        }
      }
      &-item-link {
        @extend %transition;
        background-color: $white;
        border-radius: 100%;
        border-left: 0;
        display: inline-block;
        height: 36px;
        padding: 0;
        position: relative;
        width: 36px;
    
        &:hover {
          background-color: $grey;
          
          .icon {
              color: $white;
          }
        }
        &.twitter {
            &:after {
                @include centerer(true, true);
                content: '𝕏';
                color: $black;
                font-size: $gap * 1.3;
            }
            .icon {
                color: transparent;
            }
            &:hover {
                &:after {
                    content: '𝕏';
                    color: $white;	
                }
            }
        }
        &.whatsapp {
            .icon {
                height: 1.2rem;
                width: 1.2rem;
            }
        }
        &.email {
          background-image: url("data:image/svg+xml,%3Csvg width='25' height='19' viewBox='0 0 25 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.875 0H3.125C1.40156 0 0 1.42025 0 3.16667V15.8333C0 17.5798 1.40156 19 3.125 19H21.875C23.5984 19 25 17.5798 25 15.8333V3.16667C25 1.42025 23.5984 0 21.875 0ZM18.5625 3.16667L12.5 7.55408L6.4375 3.16667H18.5625ZM3.125 15.8333V4.65975L11.5922 10.7872C11.8641 10.9852 12.1812 11.0833 12.5 11.0833C12.8188 11.0833 13.1359 10.9836 13.4078 10.7872L21.875 4.65975L21.8766 15.8333H3.125Z' fill='black'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 50%;
          
          &:hover {
            background-image: url("data:image/svg+xml,%3Csvg width='25' height='19' viewBox='0 0 25 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.875 0H3.125C1.40156 0 0 1.42025 0 3.16667V15.8333C0 17.5798 1.40156 19 3.125 19H21.875C23.5984 19 25 17.5798 25 15.8333V3.16667C25 1.42025 23.5984 0 21.875 0ZM18.5625 3.16667L12.5 7.55408L6.4375 3.16667H18.5625ZM3.125 15.8333V4.65975L11.5922 10.7872C11.8641 10.9852 12.1812 11.0833 12.5 11.0833C12.8188 11.0833 13.1359 10.9836 13.4078 10.7872L21.875 4.65975L21.8766 15.8333H3.125Z' fill='white'/%3E%3C/svg%3E%0A");  
          }
        }
        .icon {
          @include centerer(true, true);
          color: $black;
          margin: 0;
        }
      }
    }
}

.main-section {
    + .cards-outer {
        background: $grey90 !important; 
        
        + .grey {
            padding-top: 0; 
        }
    }
}

.main-section {
    + .components {
        .cards-outer:first-child {
            background: $grey90 !important; 
            
            + .grey {
                padding-top: 0; 
            }
        }
    }
}
