/* ++++++++++++++++++++
+++ LAYOUT: GALLERY +++
++++++++++++++++++++ */

.gallery {
	margin: $gap * 1.5 0;
	&-item {
        margin: 0;
        text-align: center;
	}
	&-thumb {
		padding: 0 $gap;
	}
}
