/* ++++++++++++++++
+++ LAYOUT: CTA +++
++++++++++++++++ */

.cta {
	color: $white;
	margin: 0 4vh;
	padding: $gap * 1.5 0;
	position: relative;
    text-align: center;

    @include break(small) {
		margin: 0 8vh;
		padding: $gap * 3;
    }

	&-title {
		padding: 10px 0;
	}

    &-outer {
	    background-color: $primary;
	    position: relative;

	    &.full-width-image {
		    padding: 0;

		    @include break(tablet) {
				min-height: 80vh;
		    }

		    .cta-image {
				position: relative;

				@include break(tablet) {
					position: absolute;
				}
		    }
	    }

	    &.site-width-image {
		    padding: 0;

		    @include break(tablet) {
				min-height: 60vh;
		    }
		    @include break(large) {
			    margin: 0 auto;
		    	width: $page-width;
		    }

		    .cta-image {
				position: relative;

				@include break(tablet) {
					position: absolute;
				}
		    }
	    }

	    .cta-image {
		    + .container {
			    .cta {
					background-color: rgba($white, 0.8);
					border-radius: $corner;
					box-shadow: $shadow;
					color: $black;
					margin: 4vh;
					padding: $gap * 1.5;
					z-index: 1;

					@include break(small) {
						margin: 8vh;
						padding: $gap * 3;
					}
			    }
		    }
	    }
    }

 	&-summary {
	 	padding: $gap * 1.5 0 $gap * 2;
	 	max-width: 40rem;
		
		p {
			line-height: 1.6rem;
		}
 	}

 	&-image {
	 	height: 100%;
	 	left: 0;
	 	position: absolute;
	 	top: 0;
	 	width: 100%;

	 	img {
		 	@extend %object-fit;
	 	}
 	}
}
