/* +++++++++++++++++++++++++
+++ LAYOUT: TEAM PROFILE +++
+++++++++++++++++++++++++ */

.profile {
	@extend %flex;
	align-items: flex-start;
	padding: $gap * 2 0;

	&-overlay {
		@extend %transition;
		background-color: rgba($black, 0.7);
		height: 100%;
		opacity: 0;
		left: 0;
		position: fixed;
		top: 0;
		visibility: hidden;
		width: 100%;
		z-index: 99;

		&.active-profile {
			opacity: 1;
			visibility: visible;
		}
	}

	&-item {
		cursor: pointer;
	}

	&-element {
		@extend %flex;
		@include flexbox(50%);
		align-items: flex-start;
		// cursor: pointer;
		justify-content: center;
		text-align: center;
		margin: 0 0 $gap * 2;

		@include break(small) {
			@include flexbox(25%);
			// margin: 0;
		}
		@include break(medium) {
			// @include flexbox(33.33%);
			// margin: 0;
		}
	}

	&-image {
		@extend %image-hover;
		border-radius: 100%;
		// box-shadow: 0 0 8px rgba($black, 0.2);
		cursor: pointer;
		display: inline-block;
		margin: 0 $gap * 1.5;

		@include break(medium) {
			margin: 0 $gap * 2;
		}

		@include break(large) {
			margin: 0 $gap * 3;
		}

		img {
			border-radius: 100%;
			margin: 0;
			width: 100%;
		}
	}
	&-wrap {
		margin: $gap 0 0;
		padding: 0 $gap;
	}
	&-name {
		@extend %transition;
		font-size: 1.3rem;

		&:hover {
			text-decoration: underline;
		}
	}
	&-title {
		font-size: 1rem;
		font-weight: normal;
		padding-top: $gap / 1.5;
	}
	&-summary {
	    @extend %transition;
	    background-color: $white;
	    box-shadow: $shadow;
	    height: 100%;
	    margin-top: 0;
	    padding: $gap * 5 $gap * 2 $gap * 2;
	    position: fixed;
	    right: -90%;
	    text-align: left;
	    top: 0;
	    width: 90%;
	    z-index: 999;

	    @include break(tablet) {
		    padding: $gap * 2.5;
			right: -50%;
			width: 50%;
	    }
	    @include break(large) {
		    padding: $gap * 5;
			right: -35%;
			width: 35%;
	    }

	    &.active-profile {
			right: 0;
	    }

	    .profile-summary-close {
		    @extend %transition;
	        color: $primary;
	        cursor: pointer;
	        position: absolute;
	        right: $gap * 2;
	        top: $gap * 2;
			z-index: 999;
			height: $gap * 1.5;

	        &:hover {
		        color: $secondary;
		        transform: rotate(90deg);
	        }
	    }

	    &-inner {
			display: block;
			margin-top: 0;
			position: relative;
			z-index: 11;

			.profile-image {
				margin: 0 0 $gap * 1.5 0;

				img {
					height: 12.5rem;
					width: 12.5rem;
				}
			}

			.profile-name {
				color: $primary;
				font-size: 1.3rem;

				@include break(small) {
					font-size: 1.5rem;
				}
			}

			.profile-title {
				color: $primary;
			}

			p {
				color: $black;
				// font-size: 0.9rem;
				margin-top: 0;
				padding-top: $gap;

				@include break(small) {
					// font-size: 1rem;
				}
			}
	    }
	}
    &-meta {
	    margin-top: $gap;

		&-item {
			padding: $gap / 4 0;

			&-link {
				@extend %transition;
				&:hover {
					color: $primary;

					.profile-meta-item-text {
						border-bottom: solid 2px $primary;
					}
				}
			}

			&-text {
				@extend %transition;
				margin-left: $gap / 1.5;
			}
		}
    }
}


.active-profile {
	.profile-image,
	.profile-item {
		cursor: default;
	}
	.profile-name {
		&:hover {
			text-decoration: none;
		}
	}
}
