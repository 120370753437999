/* +++++++++++++++++++++++++++
+++ LAYOUT: RESPONSIVE NAV +++
+++++++++++++++++++++++++++ */

// Layout and styling of the navigation at mobile and tablet sizes

.rnav {
    background: $grey20;
    color: $white;
    display: none;
    margin-top: 0;
    padding-top: 0;

    #rnav-open:checked + & {
        display: block;
    }

    * {
        margin-top: 0;
    }

    .search {
        display: block;
        margin: 0 $gap $gap;
        padding-top: $gap;

        &-input {
            font-family: $font-header;
            // background: rgba($white, 0.1);
            background-color: $white;
            border-color: transparent;
            color: $primary;
            max-width: 100%;
        }
    }

    &-item {
        border-top: $line solid rgba($white, 0.1);
        position: relative;

        &-link {
            @extend %transition;
            display: block;
            padding: $gap $gap * 3 $gap $gap;
            text-align: left;

            &:hover {
                // background: rgba($white, 0.1);
                background-color: $secondary;
            }
        }
    }

    &-dropdown {
        background: rgba($black, 0.2);
        display: none;

        .rnav-checkbox:checked ~ & {
            display: block;
        }
    }

    &-secnav {
	    background: lighten($grey20, 2);

	    &-item {
		    border-bottom: $line solid rgba($white, 0.1);
		    display: block;
			// padding: 0 $gap / 2;
            border-left: $gap / 2 solid $primary;

			&:last-child {
				border-bottom: 0;
			}

		    .rnav-item-link {
			    font-size: 0.9rem;
			    font-weight: $normal;

			    &:hover {
				    background-color: $secondary;
				    border-color: $primary;
				    // color: $primary;
			    }
		    }
	    }
    }

    &-arrow {
        cursor: pointer;
        padding: $gap;
        position: absolute;
        right: 0;
        top: 0;

        .icon {
            @include transition(transform 0.4s ease);
            display: block;
            width: $gap;

            .rnav-checkbox:checked + .rnav-arrow & {
                @include transform(rotate(-180deg));
            }
        }
    }

    &-button {
        border-top: $line solid rgba($white, 0.1);
        padding: $gap;
        // padding: $gap * 2.5;
        // margin-right: $gap * 2;
    }

    @include break(medium) {
        #rnav-open:checked + & {
            display: none;
        }
    }
}
