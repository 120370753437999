/* +++++++++++++++++++
+++ LAYOUT: EXPAND +++
+++++++++++++++++++ */

.expand-check {
    ~ div {
        display: none;
    }

    &:checked ~ div {
        display: block;
    }
}

.expand-trigger {
    cursor: pointer;
    margin-top: 0;

    .icon {
        @include transition(transform 0.4s ease);
    }

    .expand-check:checked ~ & {


        .icon {
            @include transform(rotate(90deg));
        }
    }
}
