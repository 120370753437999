/* +++++++++++++++++
+++ LAYOUT: HERO +++
+++++++++++++++++ */

body.home {
	.hero-outer.solid {
		.hero-image {
			+ .hero-info {
				background-color: transparent;
				
				.hero-inner {
					color: $black;
					
					@include break(tablet) {
						color: $white;
					}
				}
			}
		}
	}
}

.section.secondary {
	.hero {
		.hero-info {
			background-color: $secondary;
		}
	}
}

.section.green {
	.hero {
		.hero-info {
			background-color: $green;
		}
	}
}

.section.purple {
	.hero {
		.hero-info {
			background-color: $purple;
		}
	}
}

.hero {
	@extend %flex;
	position: relative;

	@include break(medium) {
		flex-flow: row-reverse nowrap;
	}

	&-outer {
		padding: 0;

		.hero-info {
			.hero-inner {
				// padding: 0;
				margin-left: 0;

			    @include break(medium) {
				    // @include flexbox(75%);
				    // background-color: transparent;
				    // margin-left: $gap * 2;
			    }

			    @include break(medium) {
				    @include flexbox(66.66%);
				    // margin-left: $gap * 2;
			    }
			}

		    .button {
		    	@extend %btn-small;
			    border: 2px solid $white;
			    color: $white;

			    &:hover {
			        background-color: $white;
			        color: $primary;
			    }
		    }
		}

		&.full {
			.hero-image {
				@include flexbox(100%);
				height: 100%;
				width: 100%;

				@include break(tablet) {
					left: 0;
					position: absolute;
					top: 0;
				}

				+ .hero-info {
					@include flexbox(92%);
					background-color: rgba($white, 0.5);
					// box-shadow: $shadow;
					margin: 0 auto;
					position: relative;
					text-align: center;
					z-index: 2;

					@include break(tablet) {
						@include flexbox(80%);
						border-radius: $corner;
						margin: $gap * 10 auto;
					}

					@include break(medium) {
						@include flexbox(50%);
					}

						.container {
							padding: 0;
						}

					.hero-inner {
						color: $black;
						margin: 0;
						// padding: 3rem;
						width: auto;
					}

				    .button {
				    	// @extend %btn-secondary;
				    	border: 2px solid $primary;
				    	color: $primary;

					    &:hover {
					        background-color: $primary;
					        color: $white;
					    }

				    	@include break(tablet) {
						    border: 2px solid $white;
						    color: $white;

						    &:hover {
						        background-color: $white;
						        color: $primary;
						    }
				    	}
				    }
				}
			}
			.hero-summary {
				p {
					padding: 0;
				}
			}
		}
		&.solid {
			.hero-image {
				+ .hero-info {
					@include flexbox(100%);
					background-color: $purple;
					box-shadow: none;

					@include break(tablet) {
						@include flexbox(92%);
						background-color: transparent;
						// margin: 25rem auto 0;
					}

					@include break(medium) {
						@include flexbox($page-width);
						margin: 0 $gap * 2 0 $gap * 2;
					}

					@include break(large) {
						margin: 0 auto;
						padding: 0 $gap * 2;
					}

					.hero-inner {
						color: $white;
						padding: $gap * 2.5 $gap * 1.5;
						text-align: left;

					    @include break(medium) {
						    @include flexbox(50%);
							padding: $gap * 2.5 $gap * 1.5 $gap * 2.5 0;
					    }
					}
					.button {
						border: 2px solid #FFF;
						color: #FFF;
						
						&:hover {
							background-color: $white;
							color: $black;
						}
					}
				}
			}
		}
	}
	&-info, &-image {
		margin: 0;
	}
	&-info {
		@extend %flex;
		@include flexbox(100%);
		background-color: $primary;
		align-items: center;
		
		.hero-title {
			// line-height: 2.6rem;
		}
		
		.hero-summary {
			padding-top: $gap;
			line-height: 1.6rem;
		}

		p {
			@include break(medium) {
				padding-right: $gap * 5;
			}
		}
		a {
			margin-top: $gap * 2;
		}
	}
	&-inner {
		color: $white;
		margin: 0 auto;
		// padding: 3rem 2rem;
		padding: $gap * 3 0;

		.hero-title {
			@extend %h2;
		}

		.hero-date,
		.hero-role {
		    @extend %font-label;
		}

		.hero-details {
	        list-style: none;

	        .hero-detail {
	            @extend %font-label;
	        	display: inline;

	            &:not(:first-of-type) {
	                margin-bottom: $gap * 0.75;

	                &:before {
	                    content: '|';
	                    display: inline-block;
	                    margin: 0 $gap / 2.5;
	                }
	            }
	        }
		}

		@include break(tablet) {
			width: calc($page-width - ($gap * 20));
		}

		@include break(medium) {
			// padding: 4rem 3rem 4rem 2rem;
			padding: $gap * 6 0;
		}
	}

	&-image {
		overflow: hidden;
        // @extend %overlay;

        @include break(tablet) {

	        // Can't @extend %overlay & %overlay-gradient from within @media;
		    position: relative;

		    &:after {
		        background: rgba($black, 0.8);
		        content: "";
		        height: 100%;
		        left: 0;
		        position: absolute;
		        top: 0;
		        width: 100%;
		    }
        }

		@include break(medium) {
			@include flexbox(50%);

	        // Can't @extend %overlay & %overlay-gradient from within @media;
		    &:after {
		        background: rgb(10,10,10);
		        background: -moz-linear-gradient(90deg, rgba(10,10,10,0.8981967787114846) 0%, rgba(10,10,10,0.7469362745098039) 35%, rgba(10,10,10,0) 60%);
		        background: -webkit-linear-gradient(90deg, rgba(10,10,10,0.8981967787114846) 0%, rgba(10,10,10,0.7469362745098039) 35%, rgba(10,10,10,0) 60%);
		        background: linear-gradient(90deg, rgba(10,10,10,0.8981967787114846) 0%, rgba(10,10,10,0.7469362745098039) 35%, rgba(10,10,10,0) 60%);
		        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a0a0a",endColorstr="#0a0a0a",GradientType=1);
		    }
		}
		+ .hero-info {
			@include break(medium) {
				@include flexbox(50%);
			}
			.hero-inner {
				margin: 0 0 0 auto;

				@include break(medium) {
					padding: $gap * 8 0;
					width: calc($page-width / 2);
				}
			}
		}
		img {
			@extend %object-fit;
		}
	}
}