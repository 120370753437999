/* ++++++++++++++++++++
+++ LAYOUT: EXPIRED +++
++++++++++++++++++++ */

.expired {
	text-align: center;
	@include break(small) {
		text-align: left;	
	}
	&-outer {
		background-color: #D2392E;
		padding: $gap 0 $gap * 1.5;
		
		@include break(small) {
			padding: $gap 0;	
		}
	}
	p {
		color: $white;
		font-weight: $bold;
	}
	.button {
		border-color: $white;
		color: $white;
		margin: $gap / 2 0 0;
		padding: 0.5rem 1rem 0.4rem;
		
		@include break(small) {
			margin: 0 0 0 $gap / 2;
		}
		
		&:hover {
			border-color: $black;
		}
	}
}